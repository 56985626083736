import styles from "./NewsCard.module.scss";
import cn from "clsx";
import Image from "next/image";

export default function NewsCard({
  Heading,
  url_key,
  NewsProvider,
  FeaturedImage,
  formattedDate,
  isNewsList = false,
}: any) {
  const ImageAttr = FeaturedImage?.source?.data?.[0]?.attributes;
  const provider = NewsProvider[0]?.text;

  return (
    <a
      href={url_key || ""}
      target="_blank"
      className={`block border-[0.0625rem] border-blu rounded-2xl lg:rounded-[0.833rem] ${styles.root}`}
    >
      <div
        className={cn(`flex flex-col justify-end`, styles.content, {
          [styles.contentNewsList]: isNewsList,
        })}
      >
        <Image
          src={ImageAttr?.url}
          alt={ImageAttr?.alternativeText || ""}
          className="object-cover"
          fill
          loading="lazy"
        />
      </div>
      <div className={`font-raleway ${styles.bottom}`}>
        <div className={`font-bold ${styles.heading}`}>
          {provider} . {formattedDate}
        </div>
        <div className={`font-semibold	 ${styles.text}`}>{Heading}</div>
        <div className={`homeSprite ${styles.blogArrow}`}></div>
      </div>
    </a>
  );
}
